import { DocDetail } from "@/components/itinerary/TravellerInfo/types"
import { isEmpty } from "../general/browserHelper"

export const serializeItineraryData = (data = []) => {
    const dataObj = {}
    // Todo: Discuss use of element.data check
    data.forEach(element => {
        if (element.data || element.type === 'AGENT_PAYMENT_DETAILS' || element.type === 'GST') {
            if (
                element.type === 'USER' ||
                element.type === 'GST' ||
                element.type === 'VOUCHERS' ||
                element.type === 'AGENT_PAYMENT_DETAILS'
            ) {
                dataObj[element.type] = { ...element }
            } else {
                dataObj[element.type] = element.data
            }
        }
    })
    return dataObj
}

export const getAvailableAncillary = (itineraryData : any) => {
    let ancillaryData = {
        isSeatAvailable: false,
        isMealAvailable: false,
        isBaggageAvailable: false,
        isBaggageInsuranceAvailable: false,
        isTravelInsuranceAvailable: false,
        isFareFamilyAvailable: false,
        isFlexOrCCPlusFareAvailable: false,
        isFlexMaxOrCCMaxFareAvailable: false,
        isCheckinBaggageAvailable: false
    }
    if (itineraryData?.ANCILLARY?.hasOwnProperty('tripAncillariesResponse')) {
        const { tripAncillariesResponse } = itineraryData?.ANCILLARY || {}
        if (!isEmpty(tripAncillariesResponse)) {
            tripAncillariesResponse?.forEach((journey: any = {}) => {
                journey?.ancillaryDetails?.journeyAncillaries?.forEach((segment: any) => {
                    if (segment?.baggageAncillary?.baggageDetails?.length > 0 && segment?.baggageAncillary?.baggageDetails?.some((baggage) => baggage.baggageInfos.length > 0)) {
                        ancillaryData.isBaggageAvailable = true
                    }
                    if (segment?.baggageAncillary?.baggageDetails?.some((baggageDetail: any) => {
                        return baggageDetail?.baggageInfos?.some((baggageInfo: any) => {
                            return baggageInfo?.baggageType === 'CHECKIN_BAGGAGE'
                        })
                    })) {
                        ancillaryData.isCheckinBaggageAvailable = true
                    }
                    segment?.segmentAncillaries?.forEach((ancillaries: any) => {
                        ancillaries?.seatAncillaries?.aircraftLayout?.lowerDeck?.compartments?.forEach(
                            (seats: any) => {
                                if (seats.rows?.length > 0) {
                                    ancillaryData.isSeatAvailable = true
                                }
                            }
                        )
                        if (ancillaries?.mealAncillaries?.mealInfos?.length > 0) {
                            ancillaryData.isMealAvailable = true
                        }
                    })
                })
            })
        }
    }
    if(itineraryData?.INSURANCES?.hasOwnProperty('insurances')) {
        const { insurances } = itineraryData?.INSURANCES || {}
        if (!isEmpty(insurances)) {
            insurances?.forEach((insurance) => {
                if (insurance?.insuranceType === 'BAGGAGE_INSURANCE') {
                    ancillaryData.isBaggageInsuranceAvailable = true
                }
                if (insurance?.insuranceType === 'TRAVEL_INSURANCE') {
                    ancillaryData.isTravelInsuranceAvailable = true
                }
            })
        }
    }
    if(itineraryData?.FARE_CARDS?.hasOwnProperty('fareCards')|| itineraryData?.FARE_CARDS?.hasOwnProperty('onwardFareCards') || itineraryData?.FARE_CARDS?.hasOwnProperty('returnFareCards') || itineraryData?.FARE_CARDS?.hasOwnProperty('tripFareCards')) {
        const { fareCards = [], onwardFareCards = [], returnFareCards = [], tripFareCards = [] } = itineraryData?.FARE_CARDS || {}
        const onwardFareArr = onwardFareCards || [];
        const returnFareArr = returnFareCards || [];
        const tripFareArr = tripFareCards || [];
        const allFareCards = [...fareCards, ...onwardFareArr, ...returnFareArr, ...tripFareArr]
        allFareCards.forEach((fareCard) => {
            if(fareCard.fareCardType === 'AMEND_INSURANCE') {
                ancillaryData.isFlexOrCCPlusFareAvailable = true
            }
            if((fareCard.fareCardType === 'DOM_CANCELATION_INSURANCE' || fareCard.fareCardType === 'INTL_CANCELATION_INSURANCE')) {
                ancillaryData.isFlexMaxOrCCMaxFareAvailable = true
            }
            if((fareCard.fareCardType && fareCard.fareCardType !== 'DOM_CANCELATION_INSURANCE' && fareCard.fareCardType !== 'INTL_CANCELATION_INSURANCE' && fareCard.fareCardType !== 'AMEND_INSURANCE')) {
                ancillaryData.isFareFamilyAvailable = true
            }
        })
    }
    if(itineraryData?.ADDON_CARDS?.hasOwnProperty('addOnCards') || itineraryData?.ADDON_CARDS?.hasOwnProperty('fareCards')) {
        const { addOnCards = [], fareCards = [] } = itineraryData?.ADDON_CARDS || {}
        const addOnArr = addOnCards || [];
        const fareArr = fareCards || [];
        [...addOnArr, ...fareArr].forEach((fareCard) => {
            if(fareCard.fareCardType === 'DOM_CANCELATION_INSURANCE' || fareCard.fareCardType === 'INTL_CANCELATION_INSURANCE') {
                ancillaryData.isFlexMaxOrCCMaxFareAvailable = true
            }
            if(fareCard.fareCardType === 'AMEND_INSURANCE') {
                ancillaryData.isFlexOrCCPlusFareAvailable = true
            }
        })
    }
    return ancillaryData
}

export function mergeDocDetailsForUpdate (old: DocDetail[] = [], updated: DocDetail[] = []) {
    const merged = [...old]

    updated.forEach(update => {
        if (!update.docType) {
            return merged.push(update)
        } 

        const index = merged.findIndex(doc => doc.docType === update.docType)
        if (index === -1) {
            return merged.push(update)
        }

        merged[index] = {...merged[index], ...update}
    })

    return merged
}


export function getSelectedFareCard(itineraryData: any) {
    const { fareCards = [], onwardFareCards = [], returnFareCards = [], tripFareCards = [] } = itineraryData?.FARE_CARDS || {}
    const onwardSelectedFareCard = onwardFareCards?.find((fareCard: any) => fareCard.selected)
    const returnSelectedFareCard = returnFareCards?.find((fareCard: any) => fareCard.selected)
    const tripSelectedFareCard = tripFareCards?.find((fareCard: any) => fareCard.selected)
    return { onwardSelectedFareCard, returnSelectedFareCard }
}